<template>
  <v-overlay :value="show">
    <v-card
      width="1300px"
      light
      class="pb-5"
      color="red darken-4"
    >
      <div class="d-flex justify-space-between py-3 px-5">
        <h3 class="py-2">
          Профиль риска перевозчика: {{ identity }}
        </h3>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <v-data-table
          fixed-header
          :height="500"
          hide-default-footer
          disable-sort
          class="rounded-0"
          :items-per-page="-1"
          :headers="headers"
          :items="prescriptions"
        >
          <template #[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>

import {disablePageScroll, forbiddenCarrierNotification} from "@/events/common";
import {capitalizeString} from "@/helpers/text";

export default {
  inject: ['rootNamespace'],
  data() {
    return {
      show: false,
      headers: [
        {
          text: "№",
          value: "index"
        },
        {
          text: "Предписание",
          value: "risk_precept"
        },
        {
          text: "Примечание",
          value: "risk_note"
        },
      ],
      identity: null,
      prescriptions: [],
    }
  },
  watch: {
    show(value) {
      disablePageScroll.trigger(value)
    }
  },
  created() {
    forbiddenCarrierNotification.subscribe(this.openModal)
  },
  beforeDestroy() {
    forbiddenCarrierNotification.unsubscribe()
  },
  methods: {
    clearState() {
      this.identity = null
      this.prescriptions = []
    },
    close() {
      this.clearState()
      this.show = false
    },
    setIdentity(identity) {
      this.identity = identity
    },
    setPrescriptions(prescriptions) {
      this.prescriptions = prescriptions.map(prescription => ({
        ...prescription,
        risk_precept: capitalizeString(prescription.risk_precept)
      }))
    },
    openModal({identity, prescriptions}) {
      this.setIdentity(identity);
      this.setPrescriptions(prescriptions)
      this.show = true
    }
  }
}
</script>
<style scoped>
.ware-description p,
.sanctioned-product-description p {
  margin-bottom: 5px;
}
</style>