import axios from "axios";
import {CATALOGS} from "@/http-routes";
import store from "../store"
import {forbiddenCarrierNotification} from "@/events/common";
import set from "lodash.set"

export const CARRIER_IDENTITY_WAS_CLEARED = "Идентификатор был очищен";

export function isBTS() {
  const division = store.getters['auth/getSingleDivision'];
  return division !== null && division.divisionType === "BTS"
}

function checkCarrier(unp) {
  return axios.post(`${CATALOGS}riski/checkPerevozchikBy`, {unp})
    .then((res) => res.data)
}

function checkDriver(identifPassNumber) {
  return axios.post(`${CATALOGS}riski/checkDriverBy`, {identifPassNumber})
    .then((res) => res.data)
}

function isForbiddenCarrier(identifier, isPerson = false) {
  if (!isBTS()) return Promise.reject("Недостаточно прав")
  if (!identifier) return Promise.reject("Отсутствует идентификатор");
  return isPerson ? checkDriver(identifier) : checkCarrier(identifier)
}

function showPrescriptionsIfNeed({identity, prescriptions}) {
  if (prescriptions.length) {
    forbiddenCarrierNotification.trigger({identity, prescriptions})
  }
  return prescriptions
}

function clearIdentityField({prescriptions, dataModel}) {
  if (!prescriptions.length) return;
  set(this, dataModel, null)
  this.$warning(CARRIER_IDENTITY_WAS_CLEARED);
}

export function checkIsForbidden({identity, dataModel, isPerson = false, promises}) {
  if (!identity) return;
  if (!dataModel) throw new Error("Отсутствует ссылка на dataModel");
  if (!promises) throw new Error("Отсутствует ссылка на promises");
  const promise = isForbiddenCarrier(identity, isPerson)
    .then((prescriptions) => showPrescriptionsIfNeed({identity, prescriptions}))
    .then((prescriptions) => clearIdentityField.call(this, {prescriptions, dataModel}))

  // TODO: лучше убрать из функции и просто возвращать промис, добавлять уже куда надо в самом компоненте.
  promises.push(promise)
}

